import { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import InputNumber from "components/InputNumber";
import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Form,
	Input,
	notification,
	Divider,
	Modal
} from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";

import { useImmer } from "use-immer";

import { InfoCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const [state, setState] = useImmer({});
	const { id } = props.match.params;

	const { data, loading, error, refetch } = useQuery(
		Queries.PARTNER_ACCESSES_GET,
		{
			fetchPolicy: "no-cache",
			variables: {
				user_id: id
			},
			onCompleted: (data) => {
				setState(data.PartnerAccesses)
			},
			onError: (err) => {
				console.log(err)
			}
		}
	);

	const [saveAction, {loading: loadingSave}] = useMutation(gql`
		mutation PartnerAccesses($input: PartnerAccessInput, $user_id: String!) {
			PartnerAccesses(input: $input, user_id: $user_id)
		}
	`)

	

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		try {

			let input = {...state};

			let res = await saveAction({
				variables: {
					input,
					user_id: id !== "new" ? id : null,
				},
			});

			if(res?.data?.PartnerAccesses) {
				Modal.success({
					title: "Informações atualizadas"
				})
			} else {
				Modal.error({
					title: "Erro",
					content: "Não foi possível atualzar no momento"
				})
			}

		} catch (e) {
			ErrorDialog("Erro ao salvar o parceiro. Por favor, tente novamente.");
		}
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<Form layout="vertical">
			<br />

			<Title level={3}>Maquineta Virtual</Title>

			<Row gutter={20}>
				<Col span={12}>
					<Form.Item label="Login">
						<Input.Password
							value={state.credentials_maquineta_login || null}
							onChange={(e) => {
								setState((draft) => {
									draft.credentials_maquineta_login = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Senha"
						tooltip={{
							title: "Não informar caso não deseje atualizar.",
							icon: <InfoCircleOutlined />,
						}}
					>
						<Input.Password
							value={state.credentials_maquineta_password || null}
							onChange={(e) => {
								setState((draft) => {
									draft.credentials_maquineta_password = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Divider />

			<Title level={3}>Site</Title>

			<Row gutter={20}>
				<Col span={6}>
					<Form.Item label="Valor no Site">
						<InputNumber
							value={state.credentials_maquineta_value || null}
							onChange={(e) => {
								setState((draft) => {
									draft.credentials_maquineta_value = e;
								});
							}}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Divider />

			<div>
				<Button
					onClick={save}
					type="primary"
					loading={loadingSave}
					style={{
						marginTop: 20,
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</Form>
	);
}

export default User;
