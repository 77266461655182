import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";
import _ from 'lodash';
import MaskedInput from 'antd-mask-input';
import {statuses} from './Misc';
import { Modal, Button, Col, Row, Layout, Form, Input, Select } from "antd";
import InputNumber from 'components/InputNumber';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';

function ModalView({ visible, refetch, data, close }) {

	const [state, setState] = useState(null);
	const [saveAction] = useMutation(Queries.SAVE_SERVICE);

	const save = async () => {
		
		try {
			const {_id, ...object} = data;


			const response = await saveAction({
				variables: {
					input: object,
					id: _id ? _id : null
				}
			});

			if(!response.error) {
				close();
				refetch();
			} else {
				Swal.fire({
					title: "Erro",
					text: 'Não foi possível salvar no momento. Por favor, tente novamente mais tarde.',
					type: "error",
					confirmButtonText: "OK"
				});
			}


		} catch(e) {
			console.log(e);
			Swal.fire({
				title: "Erro",
				text: 'Não foi possível salvar no momento. Por favor, tente novamente mais tarde.',
				type: "error",
				confirmButtonText: "OK"
			});
		}

		
	};

	const getValue = (path) => {
		return _.get(state, path);
	}

	const setValue = (path, value) => {
		setState(state => {
			_.set(state, path, value);
			return state;
		})
	}

	useEffect(() => {
		setState(data);
	}, [data]);


	return (
		<Modal
			title="Serviço"
			visible={visible}
			onOk={save}
			onCancel={() => {
				setState(null);
				close();
			}}
		>
			{
				state ? (
					<Form
					layout="vertical"
					>
						<Form.Item
							name="name"
							label="Nome"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input 
								defaultValue={getValue('name')}
								onChange={(e) => {
									const {value} = e.target;
									setValue('name', value);
								}}
							/>
						</Form.Item>

						<Form.Item
							name="price"
							label="Preço"
							
							rules={[
								{
									required: true,
								},
							]}
						>
							<InputNumber 
								defaultValue={getValue('price')}
								onChange={(e) => {
									setValue('price', e);
								}}
							/>
						</Form.Item>

						<Form.Item name={'description'} label="Descrição">
						  <Input.TextArea
						  	defaultValue={getValue('description')}
						  	onChange={(e) => {
						  		const {value} = e.target;
						  		setValue('description', value);
						  	}}
						  />
						</Form.Item>

						<Form.Item label="Status">
						  <Select
						  	defaultValue={statuses[getValue('status')]}
						  	onChange={(e) => {
						  		setValue('status', parseInt(e));
						  	}}
						  >
						    <Select.Option value="1">Inativo</Select.Option>
						    <Select.Option value="2">Ativo</Select.Option>
						    <Select.Option value="3">Eventual</Select.Option>
						  </Select>
						</Form.Item>

					</Form>
				) : null
			}
		</Modal>
	);
}

export default ModalView;
