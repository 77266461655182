import {
	Layout,
	Typography,
	Button,
	Card,
	Descriptions,
	Tag,
	notification,
	List,
	Modal,
	Empty,
} from "antd";

import { useQuery, useMutation, gql } from "@apollo/client";
import moment from "moment";
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function Contract({ id }) {
	const [removeSessionAction] = useMutation(
		gql`
		mutation UserSessionRemove($session_id: String!, $user_id: String!) {
			UserSessionRemove(session_id: $session_id, user_id: $user_id)
		}
	`,
		{
			onCompleted: (result) => {
				if (result.UserSessionRemove) {
					notification.success({
						message: "Sessão removida com sucesso",
					});
				} else {
					notification.error({
						message: "Não foi possível remover a sessão no momento.",
					});
				}
				sessions.refetch();
			},
			onError: (err) => {
				notification.error({
					message: "Não foi possível remover a sessão no momento.",
				});
			},
		}
	);

	const removeSession = (session) => {
		Modal.confirm({
			title: "Tem certeza que deseja remover esta sessão?",
			onOk: () => {
				removeSessionAction({
					variables: {
						session_id: session.session_id,
						user_id: id,
					},
				});
			},
		});
	};

	const sessions = useQuery(
		gql`
		query UserSessions($user_id: String!) {
         UserSessions(user_id: $user_id) {
				session_id
				data {
					location {
						country
						city
						region
					}
					user_agent
				}
				app
				createdAt
			}
      }
	`,
		{
			variables: {
				user_id: id,
			},
			fetchPolicy: "no-cache",
		}
	);

	return (
		<Card title="Sessões">
			{!sessions?.loading && sessions?.data?.UserSessions?.length ? (
				<List
					dataSource={sessions?.data?.UserSessions}
					itemLayout="horizontal"
					renderItem={(item) => (
						<List.Item
							actions={[
								<Button
									onClick={() => {
										removeSession(item);
									}}
								>
									Remover
								</Button>,
							]}
							className="session-item"
						>
							<div className="session-icon">
								{item.app ? (
									<MobileOutlined
										style={{
											fontSize: 20,
										}}
									/>
								) : (
									<DesktopOutlined
										style={{
											fontSize: 20,
										}}
									/>
								)}
							</div>
							<div className="session-info">
								<Descriptions layout="vertical" size="small">
									<Descriptions.Item label="Data">
										{item?.createdAt
											? moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")
											: "-"}
									</Descriptions.Item>
									<Descriptions.Item label="Dispositivo">
										{item?.data?.user_agent ?? "-"}
										{item.app ? (
											<>
												<Tag style={{ marginLeft: 10 }} color="orange">
													App
												</Tag>
											</>
										) : null}
									</Descriptions.Item>
									<Descriptions.Item label="Localizacao">
										{item?.data?.location ? (
											<span>
												{item.data.location.city}/{item.data.location.region}{" "}
												<br />
												{item.data.location.country}
											</span>
										) : (
											"-"
										)}
									</Descriptions.Item>
								</Descriptions>
							</div>
						</List.Item>
					)}
				/>
			) : null}

			{!sessions?.loading && !sessions?.data?.UserSessions?.length ? (
				<Empty />
			) : null}
		</Card>
	);
}

export default Contract;
