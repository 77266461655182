import React from "react";
import { Route, Switch } from "react-router-dom";
// Scenes Imports

import Dashboard from "./dashboard";
import Services from "./services";
import Tickets from "./tickets";
import * as Users from "./users";
import * as MEI from "./mei";
import DAS from "./das";
import DASInstallments from "./das/Installments";
import Invoices from "./invoices";
import Admins from "./admins";
import * as Companies from "./companies";
import * as Partners from "./partners";
import UAU from "./uau"

import NotFound from './not-found';

// Routes Config

const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/services",
    exact: true,
    component: Services,
  },
  {
    path: "/users",
    exact: true,
    component: Users.List,
  },
  {
    path: "/abertura-mei",
    exact: true,
    component: MEI.List,
  },
  {
    path: "/user/:id",
    exact: true,
    component: Users.User,
  },
  {
    path: "/user/logs/:id",
    exact: true,
    component: Users.Logs,
  },
  {
    path: "/create-user",
    exact: true,
    component: Users.New,
  },
  {
    path: "/tickets",
    exact: true,
    component: Tickets,
  },
  {
    path: "/das",
    exact: true,
    component: DAS,
  },
  {
    path: "/das-installments",
    exact: true,
    component: DASInstallments,
  },
  {
    path: "/admins",
    exact: true,
    component: Admins,
  },
  {
    path: "/companies",
    exact: true,
    component: Companies.List,
  },
  {
    path: "/company/:id",
    exact: true,
    component: Companies.View,
  },
  {
    path: "/partners",
    exact: true,
    component: Partners.List,
  },
  {
    path: "/partners/groups",
    exact: true,
    component: Partners.Groups,
  },
  {
    path: "/partner/:id",
    exact: true,
    component: Partners.View,
  },
  {
    path: "/partner/:id",
    exact: true,
    component: Partners.View,
  },
  {
    path: "/invoices",
    exact: true,
    component: Invoices,
  },
  {
    path: "/uau",
    exact: true,
    component: UAU,
  },
];

// Routes Functions

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact ? true : false}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

function RouteConfig() {
  let returnArray = [];
  routes.map((route, i) => {
    let routeObj = {
      ...route
    };
    returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
  });

  

  //return returnArray;

  return (
    <Switch>
        {returnArray}
        <Route component={NotFound}/>
    </Switch>
  )
}

export default RouteConfig;
