import React, { useEffect, useRef } from "react";
import * as Queries from "./Queries";
import { useQuery, useMutation, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import MaskPrice from "functions/mask/price";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Badge,
	Button,
	Form,
	Select,
	Input,
	Popover,
	Popconfirm,
	Tooltip,
	notification,
	Dropdown,
	Menu,
} from "antd";
import {
	EditOutlined,
	DownOutlined,
	DeleteOutlined,
	FileTextOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import MaskedInputCPFCNPJ from "components/InputCPFCNPJ";
import Swal from "sweetalert2";
import InvoiceConversation from "./InvoiceConversation";

import moment from "moment";
import { useImmer } from "use-immer";

import UserSearch from "components/UserSearch";

import Modal from "./Modal";
import ModalCreate from "./ModalCreate";
import ModalGenerate from "./ModalGenerate";

import { MessageOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function DAS() {
	const mounted = useRef(false);

	const [loadingPagination, setLoadingPagination] = useImmer(false);

	const [conversation, setConversation] = useImmer({
		visible: false,
	});

	const [deleteId, setDeleteId] = useImmer(null);
	const [modal, setModal] = useImmer({
		visible: false,
	});
	const [modalGenerate, setModalGenerate] = useImmer({
		visible: false,
	});
	const [create, setCreate] = useImmer({
		visible: false,
	});

	const [filters, setFilters] = useImmer({
		status: "",
	});

	const { data, loading, error, refetch } = useQuery(Queries.INVOICES, {
		fetchPolicy: "no-cache",
		variables: {
			page: 1,
		},
		pollInterval: 15 * 1000,
	});

	const [remove, { loading: loadingDelete }] = useMutation(
		gql`
			mutation InvoiceRemove($id: String!) {
				InvoiceRemove(id: $id)
			}
		`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const removeInvoice = async (id) => {
		Swal.fire({
			title: "Remover Nota Fiscal",
			text: "Tem certeza que deseja remover? Esta ação não pode ser desfeita.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await remove({
					variables: {
						id,
					},
				});

				if (res?.data?.InvoiceRemove) {
					refetch();
					setDeleteId(null);
					notification.success({
						message: "Nota fiscal removida com sucesso",
					});
				} else {
					setDeleteId(null);
					notification.error({
						message: "Não foi possível remover a nota no momento.",
					});
				}
			}
		});
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "_id",
			key: "_id",
			width: 120,
			render: (value, row) => {
				return (
					<Popover content={value} title="ID da Nota">
						<code>{value.slice(0, 8)}...</code>{" "}
					</Popover>
				);
			},
		},
		{
			title: "Data",
			dataIndex: "createdAt",
			key: "createdAt",
			width: 180,
			render: (value, row) => {
				return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-";
			},
		},
		{
			title: "CPF/CNPJ",
			dataIndex: "cpfcnpj",
			key: "cpfcnpj",
			width: 180,
			render: (value, row) => {
				return value || "--";
			},
		},
		{
			title: "Tomador",
			dataIndex: "taker",
			key: "taker",
			render: (value, row) => {
				return value || "--";
			},
		},
		{
			title: "Empresa",
			dataIndex: "business_name",
			key: "business_name",
			render: (value, row) => {
				return <a href={`user/${row.user}`}>{row.business_name}</a>;
			},
		},
		{
			title: "Emp. Parceiro",
			dataIndex: "partner_company",
			key: "partner_company",
			render: (value, row) => {
				return row?.partner_company?.name || "-";
			},
		},
		{
			title: "Valor",
			dataIndex: "amount",
			key: "amount",
			width: 140,
			//
			render: (value, row) => {
				return value
					? value.toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL",
					  })
					: "--";
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 140,
			render: (value) => {
				if (value === "pending")
					return <Badge color="gold" text="Aguardando" />;
				if (value === "done") return <Badge color="green" text="Finalizado" />;
				if (value === "error") return <Badge color="red" text="Erro" />;
				if (value === "sending") return <Badge color="blue" text="Gerando" />;
				if (value === "cancelled")
					return <Badge color="volcano" text="Cancelado" />;
			},
		},
		{
			title: "Chat",
			dataIndex: "",
			key: "x",
			width: 80,
			render: (value, row) => {

				return (
					<div>
						<Tooltip title="Mensagens">
							{row?.unread ? (
								<Badge dot>
									<Button
										shape="circle"
										icon={<MessageOutlined />}
										onClick={() => {
											setConversation({
												visible: true,
												data: row,
											});
										}}
									/>
								</Badge>
							) : (
								<Button
									shape="circle"
									icon={<MessageOutlined />}
									onClick={() => {
										setConversation({
											visible: true,
											data: row,
										});
									}}
								/>
							)}
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: "Ações",
			dataIndex: "actions",
			key: "actions",
			width: 80,
			render: (value, row) => {
				const menu = (
					<Menu>
						<Menu.Item
							icon={<EditOutlined />}
							onClick={() => {
								setCreate({
									visible: true,
									data: row,
								});
							}}
						>
							Editar
						</Menu.Item>
						<Menu.Item
							onClick={() => {
								setModal({
									visible: true,
									data: row,
								});
							}}
							icon={<FileTextOutlined />}
						>
							Informações
						</Menu.Item>
						{process?.env?.REACT_APP_INVOICE_GENERATOR === "1" &&
						row.status === "pending" &&
						(!row.invoice_type || row.invoice_type !== "product") ? (
							<Menu.Item
								icon={<SyncOutlined />}
								onClick={() => {
									setModalGenerate({
										visible: true,
										data: row,
									});
								}}
							>
								Gerar
							</Menu.Item>
						) : null}
						<Menu.Item
							icon={<DeleteOutlined />}
							danger
							onClick={() => {
								removeInvoice(row._id);
							}}
						>
							Remover
						</Menu.Item>
					</Menu>
				);

				return (
					<div style={{ display: "flex" }}>
						<Dropdown
							placement="bottomRight"
							overlay={menu}
							trigger={["click"]}
							arrow
						>
							<Button>
								Ações <DownOutlined />
							</Button>
						</Dropdown>
					</div>
				);
			},
		},
	];

	const onRefetch = async () => {
		refetch({
			filters,
			page: 1,
		});
		window.refetchInvoicesBadge();
	};

	const filterAction = async () => {
		setLoadingPagination(true);
		await refetch({
			filters,
			page: 1,
		});
		setLoadingPagination(false);
	};

	console.log(process.env);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Notas Fiscais</Title>
					<Button
						type="primary"
						onClick={() => {
							setCreate({ visible: true });
						}}
					>
						Criar
					</Button>
				</div>

				<div style={{ marginBottom: 10 }}>
					<Row gutter={20}>
						<Col flex="auto">
							<Form.Item label={"Usuário"}>
								<UserSearch
									onChange={(e) => {
										setFilters((d) => {
											d.user = e;
										});
									}}
									additionalProps={{
										allowClear: true,
									}}
								/>
							</Form.Item>
						</Col>
						<Col flex="auto">
							<Form.Item label={"CPF/CNPJ"}>
								<MaskedInputCPFCNPJ
									value={filters?.cpfcnpj || null}
									onChange={(e) => {
										const { value } = e.target;
										setFilters((d) => {
											d.cpfcnpj = value;
										});
									}}
									allowClear
								/>
							</Form.Item>
						</Col>
						<Col flex="auto">
							<Form.Item label={"Status"}>
								<Select
									defaultValue={""}
									onChange={(e) => {
										setFilters((d) => {
											d.status = e;
										});
									}}
								>
									<Select.Option value="">Todos</Select.Option>
									<Select.Option value="pending">Aguardando</Select.Option>
									<Select.Option value="done">Finalizado</Select.Option>
									<Select.Option value="cancelled">Cancelado</Select.Option>
									<Select.Option value="error">Erro</Select.Option>
									<Select.Option value="sending">Gerando</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col>
							<Button onClick={filterAction}>Filtrar</Button>
						</Col>
					</Row>
				</div>

				<Modal
					{...modal}
					close={() => {
						setModal({ visible: false });
					}}
					refetch={onRefetch}
				/>

				<ModalGenerate
					{...modalGenerate}
					close={() => {
						setModalGenerate({ visible: false });
					}}
					refetch={onRefetch}
				/>

				<ModalCreate
					{...create}
					close={() => {
						setCreate({ visible: false });
					}}
					refetch={onRefetch}
				/>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Invoices?.invoices || []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Invoices.currentPage,
									total: data.Invoices.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}

					<InvoiceConversation
						{...conversation}
						refetchTop={refetch}
						close={() => {
							setConversation({
								visible: false,
							});
						}}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default DAS;
