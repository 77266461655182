import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Spin, Descriptions } from "antd";
import { useLazyQuery, gql } from "@apollo/client";
import MaskPrice from "functions/mask/price";

const Observations = ({ visible, data, setModal }) => {
	const [loadData, { loading, data: response, error }] = useLazyQuery(
		gql`
      query TicketAdditionalData($id: String!) {
        TicketAdditionalData(id: $id) {
          ... on ServiceRequestDefault {
            service_type
            description
          }
          ... on ServiceRequestInvoiceRelease {
            service_type
            voterTitle
            birthDate
            externalPassword
            govBrPassword
				description
          }
          ... on ServiceRequestAnnualDeclaration {
            service_type
            year
            amount
				description
				amount_services
				amount_commerce_industry
				employee_on_period
          }
        }
      }
  `,
		{
			fetchPolicy: "no-cache",
		}
	);

	const save = async () => {
		setModal({
			visible: false,
		});
	};

	console.log(error);

	useEffect(() => {
		if (visible) {
			loadData({
				variables: {
					id: data.ticketId,
				},
			});
		}
	}, [visible]);

	return (
		<Modal
			title="Informações"
			visible={visible}
			onOk={save}
			width={800}
			onCancel={() => {
				setModal({
					visible: false,
				});
			}}
		>
			{loading ? (
				<div style={{ textAlign: "center", padding: "20px" }}>
					<Spin />
				</div>
			) : null}

			{!loading &&
			!error &&
			response?.TicketAdditionalData &&
			["InvoiceRelease", "Default", "AnnualDeclaration"].includes(
				response?.TicketAdditionalData?.service_type
			) ? (
				<Descriptions bordered layout="vertical">
					{response?.TicketAdditionalData?.service_type === "InvoiceRelease" ? (
						<>
							<Descriptions.Item label="Data de Nascimento">
								{response?.TicketAdditionalData?.birthDate}
							</Descriptions.Item>
							<Descriptions.Item label="Titulo Eleitor" span={2}>
								{response?.TicketAdditionalData?.voterTitle}
							</Descriptions.Item>
							<Descriptions.Item label="Senha Emissor Nacional">
								<code className="reveal">
									{response?.TicketAdditionalData?.externalPassword}
								</code>
							</Descriptions.Item>
							<Descriptions.Item label="Senha gov.br" span={2}>
								<code className="reveal">
									{response?.TicketAdditionalData?.govBrPassword}
								</code>
							</Descriptions.Item>
						</>
					) : null}

					{response?.TicketAdditionalData?.service_type ===
					"AnnualDeclaration" ? (
						<>
							<Descriptions.Item label="Ano">
								{response?.TicketAdditionalData?.year}
							</Descriptions.Item>
							<Descriptions.Item
								label="Receita de Comércio e Indústria"
								
							>
								{response?.TicketAdditionalData?.amount_commerce_industry
									? MaskPrice(
											response?.TicketAdditionalData?.amount_commerce_industry
									  )
									: MaskPrice(0)}
							</Descriptions.Item>
							<Descriptions.Item
								label="Receita de Prestação de Serviços"
								
							>
								{response?.TicketAdditionalData?.amount_services
									? MaskPrice(response?.TicketAdditionalData?.amount_services)
									: MaskPrice(0)}
							</Descriptions.Item>
							<Descriptions.Item label="Receita Brutal Total">
								{response?.TicketAdditionalData?.amount
									? MaskPrice(response?.TicketAdditionalData?.amount)
									: MaskPrice(0)}
							</Descriptions.Item>
							<Descriptions.Item
								label="Possui empregado durante o período abrangido pela declaração"
								span={2}
							>
								{response?.TicketAdditionalData?.employee_on_period === "yes"
									? "Sim"
									: "Não"}
							</Descriptions.Item>
						</>
					) : null}

					{response?.TicketAdditionalData?.description ? (
						<Descriptions.Item label="Descrição" span={2}>
							{response?.TicketAdditionalData?.description}
						</Descriptions.Item>
					) : null}
				</Descriptions>
			) : null}

			{!loading &&
			response &&
			!["InvoiceRelease", "Default", "AnnualDeclaration"].includes(
				response?.TicketAdditionalData?.service_type
			) ? (
				<p>Nenhuma informação</p>
			) : null}

			{error ? <p>Nenhuma informação</p> : null}
		</Modal>
	);
};

export default Observations;
